<template>
  <div id="popup-order" class="popup-order" style="display: none">
    <div class="popup-order__title" v-if="popup.title">{{ popup.title }}</div>
    <Subtitle parent-class="popup-order__desc" v-if="popup.desc">{{ popup.desc }}</Subtitle>
    <form class="popup-order__form" action="#">
      <p :class="errors.includes('your_phone') ? 'popup-order__field error' : 'popup-order__field'">
        <Label parent-class="popup-order__label">{{
          errors.includes("your_phone") ? "* Поле “Телефон” не указано" : "* Телефон"
        }}</Label>
        <span class="input_wrapper">
          <InputText
            parent-class="popup-order__input"
            type="text"
            name="popup-order-phone"
            v-input-mask
            :model-value="dataForm.your_phone"
            @update:model-value="dataForm.your_phone = $event" />
        </span>
      </p>
      <Button parent-class="popup-order__button" v-on:click="submitForm" v-if="popup.button">{{ popup.button }}</Button>
      <p :class="errors.includes('your_politics') ? 'popup-order__politics error' : 'popup-order__politics'">
        <Label class="popup-order__politics_label">
          <Checkbox
            parentClass="popup-order__politics-checkbox"
            :model-checked="dataForm.your_politics"
            @update:model-checked="dataForm.your_politics = $event" />
          <span class="popup-order__politics-text">{{
            errors.includes("your_politics")
              ? "Подтвердите согласие на обработку данных"
              : "Я согласен с персональной обработкой данных"
          }}</span>
        </Label>
      </p>
    </form>
  </div>
</template>

<script>
import Subtitle from "@/components/element/SubtitleApp.vue";
import InputText from "@/components/element/InputTextApp.vue";
import Button from "@/components/element/ButtonApp.vue";
import Checkbox from "@/components/element/CheckboxApp.vue";
import Label from "@/components/element/LabelApp.vue";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";

export default {
  components: {
    Subtitle,
    Button,
    InputText,
    Checkbox,
    Label,
  },
  data() {
    return {
      popup: this.$store.state.data.contentResponse.popup_2,
      dataForm: {
        id: 567,
        your_phone: "",
        your_politics: true,
        mertica: {
          reach: this.$metrika.reachGoal,
          metka: "zakazat_uslugy_pokritia",
        },
      },
      errors: [],
    };
  },
  methods: {
    submitForm(event) {
      event.preventDefault();
      this.errors = [];

      if (!this.dataForm.your_phone) {
        this.errors.push("your_phone");
      }

      if (!this.dataForm.your_politics) {
        this.errors.push("your_politics");
      }

      if (this.errors.length == 0) {
        this.$store.dispatch("submitForm", this.dataForm);
        this.dataForm = {
          id: 567,
          your_phone: "",
          your_politics: true,
          mertica: {
            reach: this.$metrika.reachGoal,
            metka: "zakazat_uslugy_pokritia",
          },
        };
      }
    },
  },
  mounted() {
    Fancybox.show(
      [
        {
          src: "#popup-order",
          type: "inline",
        },
      ],
      {
        on: {
          destroy: () => {
            this.$store.commit("updateOrderPopup", {
              status: false,
            });
          },
        },
        dragToClose: false,
        autoFocus: false,
      }
    );
  },
};
</script>

<style>
.popup-order {
  width: 450px;
  padding: 80px 60px;

  background-color: #ffffff;
}

.popup-order__title {
  margin-bottom: 8px;

  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 170%;
  color: #101010;
  text-align: center;
}

.popup-order__desc {
  margin-bottom: 43px;

  text-align: center;
}

.popup-order__label {
  margin-left: 15px;
  margin-bottom: 10px;
}

.popup-order__input {
  width: 100%;
  margin-bottom: 10px;
}

.popup-order__button {
  width: 100%;
  margin-bottom: 25px;
}

.popup-order__politics_label {
  display: flex;
  align-items: center;
}

.popup-order__politics-checkbox {
  margin-right: 15px;
}

.popup-order__politics-text {
  padding-top: 3px;

  font-size: 12px;

  line-height: 1.1;
  cursor: pointer;
}

.error .popup-order__politics-text {
  color: #d92641;
}

#popup-order .carousel__button.is-close {
  top: 5px;
  right: 5px;
}

@media (max-width: 1599.98px) {
  .popup-order {
    width: 380px;
    padding: 70px 50px;
  }

  .popup-order__title {
    font-size: 32px;
  }

  .popup-order__desc {
    margin-bottom: 35px;
  }

  .popup-order__button {
    width: 100%;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1199.98px) {
  .popup-order {
    width: 360px;
    padding: 50px 40px;
  }

  .popup-order__title {
    font-size: 30px;
  }

  .popup-order__desc {
    margin-bottom: 30px;
  }

  .popup-order__button {
    width: 100%;
    margin-bottom: 20px;
  }

  .popup-order__politics-checkbox {
    margin-right: 10px;
  }

  .popup-order__politics-text {
    font-size: 10px;
  }
}

@media screen and (max-width: 991.98px) {
  .popup-order {
    width: 320px;
    padding: 40px 30px;
  }

  .popup-order__title {
    font-size: 28px;
  }

  .popup-order__desc {
    margin-bottom: 30px;
  }

  .popup-order__button {
    width: 100%;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767.98px) {
  .popup-order {
    width: 290px;
    padding: 40px 20px;
  }

  .popup-order__title {
    font-size: 26px;
  }

  .popup-order__desc {
    margin-bottom: 20px;
  }

  .popup-order__button {
    width: 100%;
    margin-bottom: 20px;
  }
}
</style>
