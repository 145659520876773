import { createApp } from "vue";
import App from "./App.vue";
import Store from "@/store/index.js";
import VueYandexMetrika from "vue3-yandex-metrika";
import VueScrollTo from "vue-scrollto";
import mediaWp from "@/plugins/mediaWp.js";
import scrollMenu from "@/directives/scrollMenu.js";
import inputMask from "@/directives/inputMask.js";
import "@/assets/css/st-global-v1.css";
import "aos/dist/aos.css";

let app = createApp(App)
  .use(Store)
  .use(VueScrollTo)
  .use(mediaWp)
  .directive("scroll-menu", scrollMenu)
  .directive("input-mask", inputMask);

app.use(VueYandexMetrika, {
  id: 92316418,
  env: "production",
  options: {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true,
  },
});

app.mount("#app");
