<template>
  <button :class="classObject">
    <span class="button-text button-text--one"><slot /></span>
    <span class="button-text button-text--two"><slot /></span>
  </button>
</template>

<script>
export default {
  props: ["parentClass"],
  data() {
    return {
      classObject: {
        button: true,
        [this.parentClass]: this.parentClass,
      },
    };
  },
};
</script>

<style>
.button {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 154px;
  padding: 13px 35px;
  border: none;
  border-radius: 30px;

  font-family: 'Century Gothic';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  color: #ffffff;

  background-color: #099858;

  overflow: hidden;
  outline: none !important;
  appearance: none;
  cursor: pointer;
  transition: background-color 1s ease-in, color 0.3s linear;
}

.button-text {
  transition: transform 0.4s ease;
}

.button-text--one {
  position: static;
  transform: translateY(0);
}

.button-text--two {
  position: absolute;
  transform: translateY(70px);
}

.button:hover .button-text--two {
  transform: translateY(0);
}

.button:hover .button-text--one {
  transform: translateY(-70px);
}

@media (max-width: 1599.98px) {
  .button {
    min-width: 134px;
    padding: 10px 30px;

    font-size: 14px;
  }
}

@media (max-width: 991.98px) {
  .button {
    min-width: 119px;
    padding: 10px 28px;

    font-size: 12px;
  }
}
</style>