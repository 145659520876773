<template>
  <p :class="classObject">
    <slot />
  </p>
</template>

<script>
export default {
  props: ["parentClass"],
  data() {
    return {
      classObject: {
        subtitle: true,
        [this.parentClass]: this.parentClass,
      },
    };
  },
};
</script>

<style>
.subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  color: #686868;
}

.subtitle_color_blue {
  color: #929292;
}

@media screen and (max-width: 1599.98px) {
  .subtitle {
    font-size: 12px;
  }
}

@media screen and (max-width: 991.98px) {
  .subtitle {
    font-size: 10px;
  }
}
</style>