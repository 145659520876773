<template>
  <li :class="[classObject, imageOrientation(content)]">
    <a
      class="card-gallery__link"
      :href="$mediaWp(content)"
      data-fancybox="card-gallery"
    >
      <div class="card-gallery__icon"></div>
      <img
        class="card-gallery__img"
        :src="$mediaWp(content, 'gallery')"
        alt=""
      />
    </a>
  </li>
</template>

<script>
export default {
  props: ["parentClass", "swiper", "content"],
  data() {
    return {
      classObject: {
        ["swiper-slide"]: this.swiper,
        ["card-gallery"]: true,
        [this.parentClass]: this.parentClass,
      },
      media: this.$store.state.data.mediaResponse,
    };
  },
  methods: {
    imageOrientation(id) {
      if (!this.media[id]) {
        // Проверяем присутствует ли данный файл в Store
        console.log("Не найдено подходящего файла");
        return;
      }

      let width = this.media[id].media_details.width;
      let height = this.media[id].media_details.height;

      return width > height ? "card-gallery_horizon" : "card-gallery_vertical";
    },
  },
};
</script>
<style>
.card-gallery {
  position: relative;

  width: 330px !important;
}

.card-gallery_horizon {
  height: 330px !important;
}

.card-gallery_vertical {
  height: 442px !important;
}

.card-gallery__icon {
  position: absolute;
  top: calc(50% - 40px);
  left: calc(50% - 40px);

  display: flex;
  justify-content: center;
  align-items: center;

  width: 80px;
  height: 80px;
  border-radius: 50%;

  background-color: rgba(9, 152, 88, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease-in;
}

.card-gallery__icon::before {
  content: "";

  width: 16px;
  height: 16px;

  background-image: url(@/assets/img/magnifier.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.card-gallery:hover .card-gallery__icon {
  opacity: 1;
}

.card-gallery__img {
  width: 100%;
  height: 100%;

  object-fit: cover;
  object-position: center;
}

@media (max-width: 1599.98px) {
  .card-gallery {
    width: 246px !important;
  }

  .card-gallery_horizon {
    height: 246px !important;
  }

  .card-gallery_vertical {
    height: 315px !important;
  }

  .card-documents__icon {
    top: calc(50% - 30px);
    left: calc(50% - 30px);

    width: 60px;
    height: 60px;
  }

  .card-documents__icon::before {
    width: 14px;
    height: 14px;
  }
}

@media (max-width: 1199.98px) {
  .card-gallery {
    width: 219px !important;
  }

  .card-gallery_horizon {
    height: 219px !important;
  }

  .card-gallery_vertical {
    height: 280px !important;
  }
}

@media (max-width: 991.98px) {
  .card-gallery {
    width: 184px !important;
  }

  .card-gallery_horizon {
    height: 184px !important;
  }

  .card-gallery_vertical {
    height: 236px !important;
  }
}
</style>