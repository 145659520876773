<template>
  <h3 :class="classObject">
    <slot />
  </h3>
</template>

<script>
export default {
  props: ["parentClass"],
  data() {
    return {
      classObject: {
        header3: true,
        [this.parentClass]: this.parentClass,
      },
    };
  },
};
</script>

<style>
.header3 {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.4;
  color: #313131;
}

.header3_size_big {
  font-family: 'Lato';
  font-size: 36px;
  font-weight: 500;
  line-height: 1.7;
}

@media screen and (max-width: 1599.98px) {
  .header3 {
    font-size: 16px;
  }

  .header3_size_big {
    font-size: 30px;
  }
}

@media screen and (max-width: 1199.98px) {
  .header3 {
    font-size: 14px;
  }

  .header3_size_big {
    font-size: 24px;
  }
}

@media screen and (max-width: 991.98px) {
  .header3_size_big {
    font-size: 20px;
    line-height: 1.5;
  }
}

@media screen and (max-width: 767.98px) {
  .header3_size_big {
    font-size: 20px;
    line-height: 1.5;
  }
}
</style>