<template>
  <section id="reviews" class="reviews">
    <div class="container">
      <H2 parent-class="reviews__title">{{ content.title }}</H2>
      <Subtitle parent-class="reviews__description">
        {{ content.desc }}
      </Subtitle>
      <div class="reviews__swiper-wrapper" v-if="content.list">
        <div class="reviews__swiper swiper">
          <ul class="reviews__list swiper-wrapper">
            <ItemReviews
              swiper="true"
              v-for="item in content.list"
              :key="item"
              :content="item"
            />
          </ul>
        </div>
        <div class="reviews__slide-button">
          <ButtonSlidePrev parent-class="reviews__prev" />
          <ButtonSlideNext parent-class="reviews__next" />
          <PaginationSlide parent-class="reviews__pagination" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Swiper, { Navigation, Pagination } from "swiper";
import H2 from "@/components/element/H2App.vue";
import Subtitle from "@/components/element/SubtitleApp.vue";
import ItemReviews from "@/components/block/ItemReviewsApp.vue";
import PaginationSlide from "@/components/element/PaginationSlide.vue";
import ButtonSlideNext from "@/components/element/ButtonSlideNextApp.vue";
import ButtonSlidePrev from "@/components/element/ButtonSlidePrevApp.vue";
import "swiper/css";
import "swiper/css/pagination";

export default {
  components: {
    H2,
    Subtitle,
    ItemReviews,
    PaginationSlide,
    ButtonSlidePrev,
    ButtonSlideNext,
  },
  data() {
    return {
      content: this.$store.state.data.contentResponse.reviews,
    };
  },
  mounted() {
    new Swiper(".reviews__swiper", {
      modules: [Navigation, Pagination],
      speed: 500,
      navigation: {
        nextEl: ".reviews__next",
        prevEl: ".reviews__prev",
      },
      pagination: {
        el: ".reviews__pagination",
        type: "bullets",
      },
      breakpoints: {
        0: {
          slidesPerView: 1,
          spaceBetween: 0,
          autoHeight: true

        },
        767: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        992: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      },
    });
  },
};
</script>

<style>
.reviews {
  margin-bottom: 170px;
}

.reviews__title {
  margin-bottom: 10px;

  text-align: center;
}

.reviews__description {
  max-width: 450px;
  margin: 0 auto;
  margin-bottom: 70px;

  text-align: center;
}

.reviews__swiper-wrapper {
  position: relative;
}

.reviews__next,
.reviews__prev {
  position: absolute;
  top: calc(50% - 30px);
  z-index: 1;
}

.reviews__next {
  right: -30px;
}

.reviews__prev {
  left: -30px;
}

.reviews__pagination {
  display: none;
}

@media (max-width: 1599.98px) {
  .reviews {
    margin-bottom: 150px;
  }

  .reviews__description {
    margin-bottom: 60px;
  }

  .reviews__next,
  .reviews__prev {
    top: calc(50% - 25px);
  }

  .reviews__next {
    right: -25px;
  }

  .reviews__prev {
    left: -25px;
  }
}

@media (max-width: 1199.98px) {
  .reviews {
    margin-bottom: 130px;
  }

  .reviews__description {
    width: 364px;
    margin-bottom: 55px;
  }

  .reviews__next,
  .reviews__prev {
    top: calc(50% - 25px);
  }

  .reviews__next {
    right: -20px;
  }

  .reviews__prev {
    left: -20px;
  }
}

@media (max-width: 991.98px) {
  .reviews {
    margin-bottom: 110px;
  }

  .reviews__title {
    margin-bottom: 10px;
  }

  .reviews__description {
    margin-bottom: 50px;
  }

  .reviews__next,
  .reviews__prev {
    top: calc(50% - 20px);
  }

  .reviews__pagination {
    display: flex;
    width: 290px !important;
    margin: 35px auto 0 auto;
  }
}

@media (max-width: 767.98px) {
  .reviews {
    margin-bottom: 90px;
  }

  .reviews__description {
    width: 290px;
    margin-bottom: 45px;
  }
}
</style>