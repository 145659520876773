<template>
  <div :class="classObject"></div>
</template>

<script>
export default {
  props: ["parentClass"],
  data() {
    return {
      classObject: {
        ["slide-pagination"]: true,
        [this.parentClass]: this.parentClass,
      },
    };
  },
};
</script>

<style>
.slide-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1px;
}

.slide-pagination .swiper-pagination-bullet {
  flex-grow: 1;
  width: auto;
  height: 1px;
  margin: 0 !important;
  border-radius: 0;
  background-color: #e9eaf6;
  opacity: 1;
}
 
.slide-pagination .swiper-pagination-bullet-active {
  background-color: #099858;
}
</style>