<template>
  <span :class="classObject">
    <svg
      class="button-slide-prev__icon"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path d="M8 1L1 8.5L8 16" stroke="#ffffff" stroke-linecap="round" />
      <rect x="1" y="8" width="15" height="1" rx="0.5" fill="#ffffff" />
    </svg>
  </span>
</template>

<script>
export default {
  props: ["parentClass"],
  data() {
    return {
      classObject: {
        ["button-slide-prev"]: true,
        [this.parentClass]: this.parentClass,
      },
    };
  },
};
</script>

<style>
.button-slide-prev {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;

  background-color: rgba(9, 152, 88, 0.6);
  backdrop-filter: blur(10px);

  cursor: pointer;
  overflow: hidden;
  transition: background-color 0.2s ease;
}

.button-slide-prev:hover {
  background-color: rgba(9, 152, 88, 1);
}

.button-slide-prev:before {
  content: "";
  position: absolute;

  width: 60px;
  height: 60px;

  background-color: #066c3f;

  transform: translateX(60px);
  transition: transform 0.4s ease;
}

.button-slide-prev:hover::before {
  transform: translateX(0);
}

.button-slide-prev__icon {
  z-index: 2;

  display: block;
  width: 15px;
  height: 15px;
}

.button-slide-prev_black path {
  stroke: #ffffff;
}

.button-slide-prev_black rect {
  fill: #ffffff;
}

@media (max-width: 1599.98px) {
  .button-slide-prev {
    width: 50px;
    height: 50px;
  }

  .button-slide-prev:before {
    width: 50px;
    height: 50px;

    transform: translateX(50px);
  }
}

@media (max-width: 1199.98px) {
  .button-slide-prev {
    width: 40px;
    height: 40px;
  }

  .button-slide-prev:before {
    width: 40px;
    height: 40px;

    transform: translateX(40px);
  }
}

@media (max-width: 991.98px) {
  .button-slide-prev {
    display: none;
  }
}
</style>