<template>
  <a :class="classObject" v-html="content"></a>
</template>

<script>
export default {
  props: ["parentClass", "content"],
  data() {
    return {
      classObject: {
        logo: true,
        [this.parentClass]: this.parentClass,
      },
    };
  },
};
</script>
<style>
.logo svg {
  display: block;
}

.logo svg #text-logo * {
  transition: fill 1s ease;
}

.header.bg .logo svg #text-logo * {
  fill: #303030;
}

@media (max-width: 1599.98px) {
  .logo svg {
    width: auto;
    height: 40px;
  }
}

@media (max-width: 991.98px) {
  .logo svg {
    height: 35px;
  }
}
</style>