<template>
  <section class="offer3">
    <h2 class="visually-hidden">Торговое предложение</h2>
    <div class="offer3__content" :style="{ backgroundImage: 'url(' + $mediaWp(content.id_img) + ')' }">
      <div class="container">
        <div class="offer3__title" v-if="title" data-aos="custom-appearance" data-aos-duration="1000" v-html="title">
        </div>
        <div
          class="offer3__description"
          v-if="subTitle"
          data-aos="custom-appearance"
          data-aos-duration="1000"
          data-aos-delay="100"
          v-html="subTitle">
        </div>
        <div class="offer3__button-wrapper" data-aos="custom-appearance" data-aos-duration="1000" data-aos-delay="200">
          <Button parent-class="offer3__button" v-scroll-to="'#services'" v-if="btn">{{ btn }}</Button>
        </div>
      </div>
    </div>
    <div class="offer3__img" v-if="content.bg_img" data-aos="custom-img" data-aos-duration="1200" data-aos-delay="1000">
      <img class="offer3__img-file" :src="$mediaWp(content.bg_img)" alt="" />
    </div>
  </section>
</template>

<script>
import Button from "@/components/element/ButtonApp.vue";

export default {
  components: {
    Button,
  },
  data() {
    return {
      content: this.$store.state.data.contentResponse.offer3,
      title: "",
      subTitle: "",
      btn: "",
    };
  },
  mounted() {
    const url = new URL(window.location.href);

    for (let i = 0; i < this.content.offer_2.length; i++) {
      if (this.content.offer_2[i].offer_2_tag === url.hash) {
        this.title = this.content.offer_2[i].offer_2_title;
        this.subTitle = this.content.offer_2[i].offer_2_desc;
        this.btn = this.content.offer_2[i].offer_2_button;

        break;
      }
    }
  },
};
</script>

<style>
.offer3 {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 175px;

  background-color: #ececec;
}

.offer3__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 723px;
  padding-top: 105px;
  padding-bottom: 40px;

  background-color: #ececec;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.offer3__title {
  width: 1170px;
  margin-bottom: 40px;

  font-style: normal;
  font-weight: 400;
  font-size: 72px;
  line-height: 110%;
  color: #099858;
}

.offer3__title span {
  color: #313131;
}

.offer3__description {
  max-width: 565px;
  width: 100%;
  margin-left: 240px;
  margin-bottom: 30px;

  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 170%;
  color: #313131;
}

.offer3__button {
  margin-left: 240px;
}

.offer3__img {
  width: 100%;
  height: 665px;
}

.offer3__img-file {
  width: 100%;
  height: 100%;

  object-fit: cover;
  object-position: center;
}

@media (max-width: 1919.98px) {
  .offer3__title {
    font-size: 64px;
  }
}

@media (max-width: 1599.98px) {
  .offer3 {
    margin-bottom: 150px;
  }

  .offer3__content {
    height: 635px;
    padding-top: 71px;
    padding-bottom: 30px;
  }

  .offer3__title {
    width: 845px;
    margin-bottom: 30px;

    font-size: 48px;
  }

  .offer3__description {
    max-width: 475px;
    margin-left: 185px;
    margin-bottom: 25px;

    font-size: 18px;
    line-height: 160%;
  }

  .offer3__button {
    margin-left: 185px;
  }

  .offer3__img {
    height: 565px;
  }
}

@media (max-width: 1199.98px) {
  .offer3 {
    margin-bottom: 130px;
  }

  .offer3__content {
    height: 535px;
    padding-top: 71px;
    padding-bottom: 20px;
  }

  .offer3__title {
    width: 600px;
    margin-bottom: 20px;

    font-size: 36px;
  }

  .offer3__button {
    margin-left: 165px;
  }

  .offer3__img {
    height: 465px;
  }
}

@media (max-width: 991.98px) {
  .offer3 {
    margin-bottom: 110px;
  }

  .offer3__content {
    height: 435px;
    padding-top: 60px;
    padding-bottom: 20px;
  }

  .offer3__title {
    margin-bottom: 25px;

    font-size: 32px;
  }

  .offer3__description {
    max-width: 388px;
    margin-left: 0;
    margin-bottom: 20px;

    font-size: 14px;
  }

  .offer3__button {
    margin-left: 0;
  }

  .offer3__img {
    height: 500px;
  }
}

@media (max-width: 767.98px) {
  .offer3 {
    margin-bottom: 70px;
  }

  .offer3__content {
    padding-bottom: 10px;
  }

  .offer3__title {
    width: 100%;
    margin-bottom: 20px;

    font-size: 28px;
  }

  .offer3__description {
    max-width: none;
  }

  .offer3__button {
    margin-left: 0;
  }

  .offer3__img {
    height: 300px;
  }
}
</style>
