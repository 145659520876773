<template>
  <section id="сontacts" class="сontacts">
    <Map />
    <div class="сontacts__info">
      <H2 parent-class="сontacts__title" v-if="content.title">{{ content.title }}</H2>
      <p class="сontacts__phone" v-if="content.phone">
        <a class="сontacts__phone-link" :href="'tel:' + content.phone_link">{{ content.phone }}</a>
      </p>
      <p class="сontacts__address" v-if="content.address">
        <a class="сontacts__address-link" :href="content.address_link" target="_blank">{{ content.address }}</a>
      </p>
      <form class="сontacts__form" action="#">
        <p :class="errors.includes('your_phone') ? 'сontacts__field error' : 'сontacts__field'">
          <Label parent-class="сontacts__label">{{
            errors.includes("your_phone") ? "* Поле “Телефон” не указано" : "* Телефон"
          }}</Label>
          <span class="input_wrapper">
            <InputText
              parent-class="сontacts__input"
              type="text"
              name="contact-phone"
              v-input-mask
              :model-value="dataForm.your_phone"
              @update:model-value="dataForm.your_phone = $event" />
          </span>
        </p>
        <Button parent-class="button_type сontacts__button" v-on:click="submitForm">Перезвоните мне</Button>
        <p :class="errors.includes('your_politics') ? 'contacts__politics error' : 'contacts__politics'">
          <Label class="contacts__politics_label">
            <Checkbox
              parentClass="contacts__politics-checkbox"
              :model-checked="dataForm.your_politics"
              @update:model-checked="dataForm.your_politics = $event" />
            <span class="contacts__politics-text">{{
              errors.includes("your_politics")
                ? "Подтвердите согласие на обработку данных"
                : "Я согласен с персональной обработкой данных"
            }}</span>
          </Label>
        </p>
      </form>
    </div>
  </section>
</template>

<script>
import Map from "@/components/block/MapApp.vue";
import Button from "@/components/element/ButtonApp.vue";
import H2 from "@/components/element/H2App.vue";
import InputText from "@/components/element/InputTextApp.vue";
import Label from "@/components/element/LabelApp.vue";
import Checkbox from "@/components/element/CheckboxApp.vue";

export default {
  components: {
    Map,
    Button,
    H2,
    InputText,
    Label,
    Checkbox,
  },
  data() {
    return {
      content: this.$store.state.data.contentResponse.contacts,
      dataForm: {
        id: 298,
        your_phone: "",
        your_politics: true,
        mertica: {
          reach: this.$metrika.reachGoal,
          metka: "perezvonite_mne",
        },
      },
      errors: [],
    };
  },
  methods: {
    submitForm(event) {
      event.preventDefault();
      this.errors = [];

      if (!this.dataForm.your_phone) {
        this.errors.push("your_phone");
      }

      if (!this.dataForm.your_politics) {
        this.errors.push("your_politics");
      }

      if (this.errors.length == 0) {
        this.$store.dispatch("submitForm", this.dataForm);
        this.dataForm = {
          id: 298,
          your_phone: "",
          your_politics: true,
          mertica: {
            reach: this.$metrika.reachGoal,
            metka: "perezvonite_mne",
          },
        };
      }
    },
  },
};
</script>

<style>
.сontacts {
  position: relative;
  z-index: 0;

  display: flex;
  justify-content: center;
  min-height: 689px;
  padding-top: 70px;
  padding-bottom: 70px;
}

.сontacts__info {
  width: 690px;
  padding: 70px 120px;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: calc((-1410px / 2 + 345px) * 2);

  background-color: #ffffff;
  box-shadow: 0 0 2px 1px rgb(0 0 0 / 20%);
}

.сontacts__title {
  margin-bottom: 50px;
}

.сontacts__phone {
  margin-bottom: 15px;
}

.сontacts__phone-link {
  position: relative;

  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  text-decoration: none;
  color: #313131;
}

.сontacts__phone-link::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -2px;

  width: 0;
  height: 1px;

  background-color: #313131;
  transition: width 0.5s ease-in;
}

.сontacts__phone-link:hover::after {
  width: 100%;
}

.сontacts__address {
  margin-bottom: 35px;
}

.сontacts__address-link {
  position: relative;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-decoration: none;
  color: #313131;
}

.сontacts__address-link::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -2px;

  width: 0;
  height: 1px;

  background-color: #313131;
  transition: width 0.5s ease-in;
}

.сontacts__address-link:hover::after {
  width: 100%;
}

.сontacts__field {
  width: 330px;
  margin-bottom: 10px;
}

.сontacts__label {
  display: block;
  margin-left: 15px;
  margin-bottom: 10px;
}

.contacts__politics_label {
  display: flex;
  align-items: center;
}

.contacts__politics-checkbox {
  margin-right: 15px;
}

.contacts__politics-text {
  padding-top: 2px;

  line-height: 1.1;

  cursor: pointer;
}

.error .contacts__politics-text {
  color: #d92641;
}

.сontacts__button {
  width: 330px;
  margin-bottom: 25px;
}

@media screen and (max-width: 1599.98px) {
  .сontacts {
    min-height: 540px;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .сontacts__info {
    width: 522px;
    padding: 50px 93px;
    margin-right: calc((-1074px / 2 + 261px) * 2);
  }

  .сontacts__title {
    margin-bottom: 30px;
  }

  .сontacts__phone {
    margin-bottom: 17px;
  }

  .сontacts__phone-link {
    font-size: 16px;
  }

  .сontacts__address {
    margin-bottom: 41px;
  }

  .сontacts__address-link {
    font-size: 14px;
  }

  .сontacts__field {
    width: 276px;
  }

  .contacts__politics-checkbox {
    margin-right: 10px;
  }

  .сontacts__button {
    width: 276px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1199.98px) {
  .сontacts {
    min-height: 508px;
    padding-top: 45px;
    padding-bottom: 45px;
  }

  .сontacts__info {
    width: 468px;
    padding: 55px 84px;
    margin-right: calc((-966px / 2 + 234px) * 2);
  }

  .сontacts__title {
    margin-bottom: 32px;
  }

  .сontacts__phone {
    margin-bottom: 15px;
  }

  .сontacts__phone-link {
    font-size: 14px;
  }

  .сontacts__address {
    margin-bottom: 30px;
  }

  .сontacts__address-link {
    font-size: 12px;
  }

  .сontacts__field {
    width: 249px;
  }

  .сontacts__button {
    width: 249px;
  }
}

@media screen and (max-width: 991.98px) {
  .сontacts {
    min-height: 472px;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .сontacts__info {
    width: 392px;
    padding: 50px 41px;
    margin-right: calc((-592px / 2 + 196px) * 2);
  }

  .сontacts__title {
    margin-bottom: 30px;
  }

  .сontacts__phone {
    margin-bottom: 10px;
  }

  .сontacts__address {
    margin-bottom: 32px;
  }

  .сontacts__field {
    width: 240px;
  }

  .сontacts__button {
    width: 240px;
  }
}

@media screen and (max-width: 767.98px) {
  .сontacts {
    min-height: 614px;
    padding-top: 35px;
    padding-bottom: 200px;
  }

  .сontacts__info {
    width: 290px;
    padding: 45px 22px;
    margin-right: calc((-536px / 2 + 145px) * 2);
  }
}

@media screen and (max-width: 575.98px) {
  .сontacts__info {
    margin-right: calc((-493px / 2 + 145px) * 2);
  }
}

@media screen and (max-width: 532.98px) {
  .сontacts {
    min-height: auto;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 90px;
  }

  .сontacts__info {
    margin-right: 0;
  }
}
</style>
