<template>
  <div :class="classObject">
    <span class="burger__item"></span>
    <span class="burger__item burger__item_size_min"></span>
    <span class="burger__item"></span>
  </div>
</template>
<script>
export default {
  props: ["parentClass"],
  data() {
    return {
      classObject: {
        burger: true,
        [this.parentClass]: this.parentClass,
      },
    };
  },
};
</script>
<style>
.burger {
  width: 43px;
  height: 43px;
  padding: 15px 12px;
  border-radius: 50%;

  background-color: rgba(9, 152, 88, 0.8);
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  cursor: pointer;
}

.burger__item {
  display: block;
  width: 19px;
  height: 1px;
  border-radius: 5px;
  margin-bottom: 5px;

  background-color: #ffffff;

  transform: rotate(0deg) translate(0, 0);
  transition: transform 0.3s ease-out;
}

.burger__item:last-child {
  margin-bottom: 0px;
}

.mobile .burger__item:nth-child(1) {
  transform: rotate(45deg) translate(4px, 4px);
}

.mobile .burger__item:nth-child(3) {
  transform: rotate(-45deg) translate(4px, -4px);
}

.mobile .burger__item_size_min {
  opacity: 0;
}

.burger__item_size_min {
  width: 13px;
  margin-left: auto;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.mobile .burger__item_size_min {
  opacity: 0;
}
</style>