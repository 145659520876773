<template>
  <li class="card-documents" v-if="content">
    <a
      class="card-documents__link"
      data-fancybox="card-documents"
      :href="$mediaWp(content)"
    >
      <div class="card-documents__icon"></div>
      <img
        class="card-documents__img"
        :src="$mediaWp(content, 'documents')"
        alt=""
      />
    </a>
  </li>
</template>
<script>
export default {
  props: ["content"],
};
</script>
<style>
.card-documents {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px 25px;

  background-color: #e6f7ea;
}

.card-documents__icon {
  position: absolute;
  top: calc(50% - 40px);
  left: calc(50% - 40px);

  display: flex;
  justify-content: center;
  align-items: center;

  width: 80px;
  height: 80px;
  border-radius: 50%;

  background-color: rgba(9, 152, 88, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease-in;
}

.card-documents__icon::before {
  content: "";

  width: 16px;
  height: 16px;

  background-image: url(@/assets/img/magnifier.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.card-documents__link:hover .card-documents__icon {
  opacity: 1;
}

.card-documents__img {
  display: block;
  width: 160px;
  height: 206px;
  object-fit: contain;
  object-position: center;
}

@media (max-width: 1599.98px) {
  .card-documents {
    padding: 23px 19px;
  }

  .card-documents__icon {
    top: calc(50% - 30px);
    left: calc(50% - 30px);

    width: 60px;
    height: 60px;
  }

  .card-documents__icon::before {
    width: 14px;
    height: 14px;
  }

  .card-documents__img {
    width: 117px;
    height: 151px;
  }
}

@media (max-width: 1199.98px) {
  .card-documents {
    padding: 20px 17px;
  }

  .card-documents__img {
    width: 103px;
    height: 133px;
  }
}

@media (max-width: 991.98px) {
  .card-documents {
    padding: 27px 22px;
  }

  .card-documents__img {
    width: 140px;
    height: 179px;
  }
}

@media (max-width: 767.98px) {
  .card-documents {
    padding: 35px 25px;
  }

  .card-documents__img {
    width: 208px;
    height: 258px;
  }
}

@media screen and (max-width: 575.98px) {
  .card-documents__img {
    width: 186px;
    height: 240px;
  }
}

@media screen and (max-width: 532.98px) {
  .card-documents {
    padding: 25px 20px;
  }

  .card-documents__img {
    width: 142px;
    height: 186px;
  }
}

@media screen and (max-width: 424.98px) {
  .card-documents {
    padding: 20px 17px;
  }

  .card-documents__img {
    width: 115px;
    height: 145px;
  }
}

@media screen and (max-width: 359.98px) {
  .card-documents__img {
    width: 105px;
    height: 135px;
  }
}
</style>