<template>
  <section class="offer-2">
    <h2 class="visually-hidden">Торговое предложение</h2>
    <div class="offer-2__swiper swiper">
      <div class="offer-2__wrapper swiper-wrapper">
        <div
          class="offer-2__slide swiper-slide"
          :style="{
            backgroundImage: 'url(' + $mediaWp(item.img, 'offer2') + ')',
          }"
          v-for="item in content.list"
          :key="item">
          <div class="container">
            <div class="offer-2__content">
              <div class="offer-2__title" v-html="item.title" if="item.title"></div>
              <div class="offer-2__desc" v-html="item.desc" if="item.desc"></div>
              <Button parent-class="offer-2__button" v-on:click="openPopup()" if="item.button">{{
                item.button
              }}</Button>
            </div>
          </div>
        </div>
      </div>
      <div class="offer-2__slide-button">
        <ButtonSlidePrev parent-class="button-slide-prev_black offer-2__prev" />
        <ButtonSlideNext parent-class="button-slide-next_black offer-2__next" />
      </div>
      <div class="offer-2__icon">
        <img class="offer-2__icon-img" src="@/assets/img/offer-2_icon.png" alt="" />
        <span class="offer-2__icon-circle">
          <span class="offer-2__icon-circle1"></span>
          <span class="offer-2__icon-circle2"></span>
          <span class="offer-2__icon-circle3"></span>
        </span>
      </div>
    </div>
    <PaginationSlide parent-class="offer-2__pagination" />
  </section>
</template>

<script>
import Button from "@/components/element/ButtonApp.vue";
import PaginationSlide from "@/components/element/PaginationSlide.vue";
import ButtonSlideNext from "@/components/element/ButtonSlideNextApp.vue";
import ButtonSlidePrev from "@/components/element/ButtonSlidePrevApp.vue";
import Swiper, { Navigation, Pagination, EffectFade, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

export default {
  components: {
    Button,
    PaginationSlide,
    ButtonSlidePrev,
    ButtonSlideNext,
  },
  data() {
    return {
      content: this.$store.state.data.contentResponse.offer2,
    };
  },
  methods: {
    openPopup() {
      this.$store.commit("updateOrderPopup", {
        status: true,
      });
    },
  },
  mounted() {
    new Swiper(".offer-2__swiper", {
      modules: [Navigation, Pagination, EffectFade, Autoplay],
      slidesPerView: 1,
      spaceBetween: 0,
      loop: true,
      speed: 1000,
      grabCursor: true,
      effect: "fade",
      autoplay: {
        enabled: true,
        delay: 7000,
        reverseDirection: true,
      },
      navigation: {
        nextEl: ".offer-2__next",
        prevEl: ".offer-2__prev",
      },
      pagination: {
        el: ".offer-2__pagination",
        type: "bullets",
      },
    });
  },
};
</script>

<style>
.offer-2 {
  position: relative;
  z-index: 0;

  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  min-height: 730px;
  margin: 0 auto;
  margin-bottom: 170px;

  overflow: hidden;
}

.offer-2__swiper {
  height: 100%;
}

.offer-2__wrapper {
  height: 100%;
}

.offer-2__slide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding-top: 105px;
  padding-bottom: 80px;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.offer-2__title {
  margin-bottom: 40px;

  font-style: normal;
  font-weight: 500;
  font-size: 64px;
  line-height: 130%;
  color: #ffffff;
}

.offer-2__desc {
  width: 569px;
  margin-bottom: 40px;

  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 170%;
  color: #ffffff;
}

.offer-2__icon {
  position: absolute;
  right: calc(50% - 610px);
  bottom: 0;
  z-index: 1;

  pointer-events: none;
}

.offer-2__icon-img {
  width: auto;
  height: 85vh;
}

.offer-2__icon-circle {
  content: "";
  position: absolute;
  right: -22vh;
  bottom: 50px;
  z-index: -1;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 85vh;
  height: 85vh;
  border-radius: 50%;

  overflow: hidden;
}

.offer-2__icon-circle1 {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;

  background-color: rgba(0, 32, 86, 0.2);
  transform: scale(0.4);
  animation: pulse-offer1 2000ms ease-out infinite;
}

@keyframes pulse-offer1 {
  0% {
    transform: scale(0.4);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.offer-2__icon-circle2 {
  position: absolute;

  display: block;
  width: 70%;
  height: 70%;
  border-radius: 50%;

  transform: scale(0.57);
  background-color: rgba(0, 32, 86, 0.25);
  animation: pulse-offer2 2000ms linear infinite;
}

@keyframes pulse-offer2 {
  0% {
    transform: scale(0.57);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.offer-2__icon-circle3 {
  position: absolute;

  display: block;
  width: 40%;
  height: 40%;
  border-radius: 50%;

  background-color: rgba(0, 32, 86, 0.3);
}

.offer-2__slide-button {
  position: absolute;
  left: calc(50% - 705px);
  bottom: 65px;
  z-index: 1;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 140px;
}

.offer-2__pagination {
  display: none;
}

@media (max-width: 1599.98px) {
  .offer-2 {
    min-height: 650px;
    margin-bottom: 150px;
  }

  .offer-2__slide {
    padding-top: 71px;
    padding-bottom: 80px;
  }

  .offer-2__title {
    width: 600px;
    margin-bottom: 30px;

    font-size: 48px;
  }

  .offer-2__desc {
    width: 520px;
    margin-bottom: 30px;

    font-size: 16px;
    line-height: 160%;
  }

  .offer-2__icon {
    right: calc(50% - 510px);
  }

  .offer-2__slide-button {
    left: calc(50% - 535px);
    width: 120px;
  }

  .offer-2__pagination {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .offer-2 {
    margin-bottom: 130px;
  }

  .offer-2__slide {
    padding-bottom: 80px;
  }

  .offer-2__title {
    width: 500px;
    margin-bottom: 20px;

    font-size: 36px;
  }

  .offer-2__desc {
    width: 480px;
    margin-bottom: 20px;
  }

  .offer-2__icon {
    right: calc(50% - 450px);
  }

  .offer-2__slide-button {
    left: calc(50% - 480px);
    width: 100px;
  }
}

@media (max-width: 991.98px) {
  .offer-2 {
    margin-bottom: 130px;
  }

  .offer-2__slide {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .offer-2__swiper {
    height: calc(100vh - 40px);
  }

  .offer-2__title {
    width: 450px;
    margin-bottom: 20px;

    font-size: 32px;
  }

  .offer-2__desc {
    width: 400px;

    font-size: 14px;
  }

  .offer-2__icon {
    bottom: 0;
    right: calc(50% - 450px);
  }

  .offer-2__slide-button {
    display: none;
  }

  .offer-2__pagination {
    display: flex;
    width: 290px !important;
    margin: 20px auto 0 auto;
  }
}

@media (max-width: 767.98px) {
  .offer-2 {
    margin-bottom: 130px;
  }

  .offer-2__slide {
    padding-bottom: 90px;
  }

  .offer-2__content {
    widows: 100%;
    max-width: 450px;
  }

  .offer-2__title {
    width: 100%;
    margin-bottom: 15px;

    font-size: 28px;
  }

  .offer-2__desc {
    width: 100%;
    margin-bottom: 20px;
  }

  .offer-2__icon {
    display: none;
  }

  .offer-2__slide-button {
    display: none;
  }
}
</style>
