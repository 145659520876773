<template>
  <li :class="classObject">
    <H3 class="header3_size_big card-price__name" v-if="content.name">{{
      content.name
    }}</H3>
    <p class="card-price__cost" v-if="content.cost">{{ content.cost }}</p>
    <div
      class="card-price__specifications"
      v-html="content.desc"
      v-if="content.desc"
    ></div>
    <Button
      parent-class="card-price__button"
      v-on:click="openPopup()"
      v-if="content.button"
      >{{ content.button }}</Button
    >
  </li>
</template>

<script>
import H3 from "@/components/element/H3App.vue";
import Button from "@/components/element/ButtonApp.vue";

export default {
  components: {
    Button,
    H3,
  },
  props: ["parentClass", "swiper", "content"],
  data() {
    return {
      classObject: {
        ["swiper-slide"]: this.swiper,
        ["card-price"]: true,
        [this.parentClass]: this.parentClass,
      },
    };
  },
  methods: {
    openPopup() {
      this.$store.commit("updateOrderPopup", {
        status: true,
      });
    },
  },
};
</script>

<style>
.card-price {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto!important;
  padding: 70px 66px;

  text-align: center;

  background-color: #e6f7ea;
}

.card-price__name {
  position: relative;

  margin-bottom: 41px;
}

.card-price__name::before {
  content: "";
  position: absolute;
  bottom: -20px;
  left: calc(50% - 50px);

  width: 100px;
  height: 1px;

  background-color: #399858;
}

.card-price__cost {
  margin-bottom: 35px;

  font-weight: 700;
  font-size: 48px;
  line-height: 130%;
  color: #101010;
}

.card-price__specifications {
  margin-bottom: 35px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 200%;
  text-align: center;
  color: #929292;
}

.card-price__button {
  margin: 0 auto;
  margin-top: auto;
}

@media (max-width: 1599.98px) {
  .card-price {
    padding: 50px 37px;
  }

  .card-price__title {
    margin-bottom: 60px;
  }

  .card-price__slide {
    padding: 50px 37px;
  }

  .card-price__name {
    margin-bottom: 36px;
  }

  .card-price__name::before {
    bottom: -15px;
    left: calc(50% - 45px);

    width: 90px;
  }

  .card-price__cost {
    margin-bottom: 30px;

    font-size: 40px;
  }

  .card-price__specifications {
    margin-bottom: 30px;
  }

  .card-price__property {
    font-size: 12px;
    line-height: 190%;
  }
}

@media (max-width: 1199.98px) {
  .card-price {
    padding: 47px 45px;
  }

  .card-price__title {
    margin-bottom: 55px;
  }

  .card-price__slide {
    padding: 47px 45px;
  }

  .card-price__name {
    margin-bottom: 35px;
  }

  .card-price__name::before {
    left: calc(50% - 40px);

    width: 80px;
  }

  .card-price__cost {
    margin-bottom: 28px;

    font-size: 34px;
  }

  .card-price__specifications {
    margin-bottom: 15px;
  }
}

@media (max-width: 991.98px) {
  .card-price {
    padding: 40px 10px;
  }

  .card-price__title {
    margin-bottom: 50px;
  }

  .card-price__slide {
    padding: 40px 10px;
  }

  .card-price__name {
    margin-bottom: 35px;
  }

  .card-price__name::before {
    left: calc(50% - 25px);

    width: 50px;
  }

  .card-price__cost {
    margin-bottom: 23px;

    font-size: 28px;
  }

  .card-price__specifications {
    margin-bottom: 15px;
  }
}

@media (max-width: 767.98px) {
  .card-price {
    padding: 30px;
  }

  .card-price__title {
    margin-bottom: 38px;
  }

  .card-price__slide {
    width: 246px;
    padding: 30px;
  }

  .card-price__name {
    margin-bottom: 35px;
  }

  .card-price__name::before {
    left: calc(50% - 38px);

    width: 76px;
  }

  .card-price__cost {
    margin-bottom: 20px;
  }

  .card-price__specifications {
    margin-bottom: 20px;
  }
}
</style>