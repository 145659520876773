<template>
  <section id="services" class="services">
    <div class="container">
      <H2 parent-class="services__title" v-if="content.title">{{ content.title }}</H2>
      <Subtitle parent-class="services__description" v-if="content.desc">
        {{ content.desc }}
      </Subtitle>
      <ul class="services__list" v-if="content.list">
        <ItemService v-for="item in content.list.slice(0, countItem)" :key="item" :content="item" />
      </ul>
      <ButtonLink parent-class="services__button" v-if="content.list.length > countItem" v-on:click="outputItem"
        >Смотреть еще</ButtonLink
      >
    </div>
  </section>
</template>

<script>
import ItemService from "@/components/block/ItemServiceApp.vue";
import H2 from "@/components/element/H2App.vue";
import Subtitle from "@/components/element/SubtitleApp.vue";
import ButtonLink from "@/components/element/ButtonLinkApp.vue";

export default {
  components: {
    H2,
    Subtitle,
    ButtonLink,
    ItemService,
  },
  data() {
    return {
      content: this.$store.state.data.contentResponse.services,
      countItem: window.innerWidth > 991.98 ? 100 : 100,
    };
  },
  methods: {
    outputItem() {
      this.countItem *= 2;
    },
  },
};
</script>

<style>
.services {
  margin-bottom: 170px;
  height: auto;
  overflow: hidden;
  transition: height 1s ease;
}

.services__title {
  margin-bottom: 10px;

  text-align: center;
}

.services__description {
  max-width: 450px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 70px;

  text-align: center;
}

.services__list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 60px 30px;
  grid-template-areas: ". . . .";
  margin-bottom: 70px;
}

.services__button {
  margin: 0 auto;
}

@media (max-width: 1599.98px) {
  .services {
    margin-bottom: 150px;
  }

  .services__description {
    margin-bottom: 60px;
  }

  .services__list {
    margin-bottom: 60px;
  }
}

@media (max-width: 1199.98px) {
  .services {
    margin-bottom: 130px;
  }

  .services__description {
    margin-bottom: 55px;
  }

  .services__list {
    margin-bottom: 55px;
  }
}

@media (max-width: 991.98px) {
  .services {
    margin-bottom: 110px;
  }

  .services__description {
    margin-bottom: 50px;
  }

  .services__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 40px 20px;
    grid-template-areas: ". .";
    margin-bottom: 50px;
  }
}

@media (max-width: 767.98px) {
  .services {
    margin-bottom: 90px;
  }

  .services__description {
    margin-bottom: 45px;
  }

  .services__list {
    gap: 28px 14px;
  }
}
</style>
