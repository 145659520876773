<template>
  <section id="price" class="price">
    <div class="price__container container">
      <H2 class="price__title" v-if="content.title">{{ content.title }}</H2>
      <div class="price__swiper swiper">
        <ul class="price__swiper-wrapper swiper-wrapper">
          <ItemPrice
            parent-class="price__slide"
            swiper="true"
            v-for="item in content.list"
            :key="item"
            :content="item"
          />
        </ul>
        <PaginationSlide parent-class="price__pagination" />
      </div>
    </div>
  </section>
</template>

<script>
import H2 from "@/components/element/H2App.vue";
import ItemPrice from "@/components/block/ItemPriceApp.vue";
import PaginationSlide from "@/components/element/PaginationSlide.vue";
import Swiper, { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

export default {
  components: {
    H2,
    ItemPrice,
    PaginationSlide,
  },
  data() {
    return {
      content: this.$store.state.data.contentResponse.price,
    };
  },
  mounted() {
    let slides = document.querySelectorAll(".price__slide");
    let inSlide = slides.length > 3;

    let distance = document.getElementById("container").getBoundingClientRect();

    new Swiper(".price__swiper", {
      slidesPerView: "auto",
      spaceBetween: 15,
      modules: [Pagination],
      freeMode: true,
      speed: 500,
      loop: false,
      slidesOffsetBefore: distance.left,
      slidesOffsetAfter: distance.left,
      pagination: {
        el: ".price__pagination",
        type: "bullets",
      },
      breakpoints: {
        768: {
          enabled: inSlide,
          slidesPerView: 3,
          spaceBetween: 20,
          slidesOffsetBefore: 0,
        },
        992: {
          spaceBetween: 30,
          enabled: inSlide,
          slidesPerView: 3,
          slidesOffsetBefore: 0,
        },
      },
    });
  },
};
</script>

<style>
.price {
  margin-bottom: 170px;
}

.price__title {
  margin-bottom: 70px;

  text-align: center;
}

.price__pagination {
  width: 290px !important;
  margin: 35px auto 0 auto;
}

@media (max-width: 1599.98px) {
  .price {
    margin-bottom: 150px;
  }

  .price__title {
    margin-bottom: 60px;
  }
}

@media (max-width: 1199.98px) {
  .price {
    margin-bottom: 130px;
  }

  .price__title {
    margin-bottom: 55px;
  }
}

@media (max-width: 991.98px) {
  .price {
    margin-bottom: 110px;
  }

  .price__title {
    margin-bottom: 50px;
  }
}

@media (max-width: 767.98px) {
  .price {
    margin-bottom: 90px;
  }

  .price__container {
    max-width: 100% !important;
  }

  .price__title {
    margin-bottom: 38px;
  }

  .price__slide {
    width: 246px;
  }
}
</style>