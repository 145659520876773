import Store from '@/store/index.js' // Подключаем Store приложения

export default {
  install: (Vue) => {
    Vue.config.globalProperties.$mediaWp = (id, size) => { // Определяем метод mediaWp в нашем приложении
      let mediaApp = Store.state.data.mediaResponse; // Получаем ссылку на медиафайлы приложения

      if (!mediaApp[id]) { // Проверяем присутствует ли данный файл в Store
        console.log('Не найдено подходящего файла');
        return;
      }

      let typeFile = mediaApp[id].mime_type.split('/')[0] // Получаем контент тип контента

      if (typeFile === "image") { // Проверяем является ли запрашиваемый файл изображением
        let imagesSize = mediaApp[id].media_details.sizes;

        if (!size || !imagesSize[size]) { // Проверяем передан ли размер и присутствует ли он у изображения
          return imagesSize.full.source_url;
        }

        return imagesSize[size].source_url; // Возвращаем ссылку изображения нужного размера
      }

      if (typeFile === "video") { // Проверяем является ли запрашиваемый файл видео
        return mediaApp[id].source_url; // Возвращаем ссылку на видео
      }
    }
  }
}
