<template>
  <section id="stocks" class="stocks">
    <div class="stocks__left">
      <div class="stocks__content">
        <H2
          parent-class="stocks__title"
          v-if="content.title"
          data-aos="custom-appearance"
          data-aos-duration="1000"
          v-html="content.title"></H2>
        <Subtitle
          parent-class="subtitle_color_blue stocks__subtitle"
          v-if="content.desc"
          data-aos="custom-appearance"
          data-aos-duration="1000"
          v-html="content.desc"></Subtitle>
        <Timer
          parent-class="stocks__timer"
          :timer="content.timer"
          v-if="content.timer"
          data-aos="custom-appearance"
          data-aos-duration="1000" />
        <form class="stocks__form" action="#" data-aos="custom-appearance" data-aos-duration="1000">
          <p :class="errors.includes('your_name') ? 'stocks__field error' : 'stocks__field'">
            <Label parent-class="stocks__label">{{
              errors.includes("your_name") ? "* Поле “Имя” не указано" : "* Имя"
            }}</Label>
            <span class="input_wrapper">
              <InputText
                parent-class="stocks__input"
                type="text"
                name="stocks-name"
                :model-value="dataForm.your_name"
                @update:model-value="dataForm.your_name = $event" />
            </span>
          </p>
          <p :class="errors.includes('your_phone') ? 'stocks__field error' : 'stocks__field'">
            <Label parent-class="stocks__label">{{
              errors.includes("your_phone") ? "* Поле “Телефон” не указано" : "* Телефон"
            }}</Label>
            <span class="input_wrapper">
              <InputText
                parent-class="stocks__input"
                type="text"
                name="stocks-phone"
                v-input-mask
                :model-value="dataForm.your_phone"
                @update:model-value="dataForm.your_phone = $event" />
            </span>
          </p>
          <Button parent-class="stocks__button" v-on:click="submitForm">Участвовать</Button>
          <p :class="errors.includes('your_politics') ? 'stocks__politics error' : 'stocks__politics'">
            <Label class="stocks__politics_label">
              <Checkbox
                parentClass="stocks__politics-checkbox"
                :model-checked="dataForm.your_politics"
                @update:model-checked="dataForm.your_politics = $event" />
              <span class="stocks__politics-text">{{
                errors.includes("your_politics")
                  ? "Подтвердите согласие на обработку данных"
                  : "Я согласен с персональной обработкой данных"
              }}</span>
            </Label>
          </p>
        </form>
      </div>
    </div>
    <div class="stocks__right">
      <img
        class="stocks__img"
        :src="$mediaWp(content.id_img, 'stocks')"
        alt=""
        data-aos="custom-img"
        data-aos-duration="1000" />
    </div>
  </section>
</template>

<script>
import Button from "@/components/element/ButtonApp.vue";
import Checkbox from "@/components/element/CheckboxApp.vue";
import H2 from "@/components/element/H2App.vue";
import Subtitle from "@/components/element/SubtitleApp.vue";
import Timer from "@/components/block/TimerApp.vue";
import InputText from "@/components/element/InputTextApp.vue";
import Label from "@/components/element/LabelApp.vue";

export default {
  components: {
    Button,
    Checkbox,
    H2,
    Subtitle,
    Timer,
    InputText,
    Label,
  },
  data() {
    return {
      content: this.$store.state.data.contentResponse.stocks,
      dataForm: {
        id: 299,
        your_name: "",
        your_phone: "",
        your_politics: true,
        mertica: {
          reach: this.$metrika.reachGoal,
          metka: "akcia",
        },
      },
      errors: [],
    };
  },
  methods: {
    submitForm(event) {
      event.preventDefault();
      this.errors = [];

      if (!this.dataForm.your_name) {
        this.errors.push("your_name");
      }

      if (!this.dataForm.your_phone) {
        this.errors.push("your_phone");
      }

      if (!this.dataForm.your_politics) {
        this.errors.push("your_politics");
      }

      if (this.errors.length == 0) {
        this.$store.dispatch("submitForm", this.dataForm);
        this.dataForm = {
          id: 299,
          your_name: "",
          your_phone: "",
          your_politics: true,
          mertica: {
            reach: this.$metrika.reachGoal,
            metka: "akcia",
          },
        };
      }
    },
  },
};
</script>

<style>
.stocks {
  display: flex;
  justify-content: center;
  min-height: 928px;
  margin-bottom: 170px;

  background-color: #f3f3f3;
}

.stocks__left {
  display: flex;
  justify-content: flex-end;
  flex-shrink: 0;
  width: calc(50% - 15px);
  padding: 110px 120px 110px 0;
}

.stocks__content {
  width: 569px;
}

.stocks__title {
  margin-bottom: 8px;
}

.stocks__subtitle {
  padding-right: 120px;
  margin-bottom: 60px;
}

.stocks__timer {
  margin-bottom: 70px;
}

.stocks__field {
  width: 330px;
  margin-bottom: 15px;
}

.stocks__field:nth-child(2) {
  margin-bottom: 35px;
}

.stocks__label {
  display: block;
  margin-left: 10px;
  margin-bottom: 10px;
}

.stocks__button {
  width: 330px;
  margin-bottom: 28px;
}

.stocks__politics_label {
  display: flex;
  align-items: flex-start;

  cursor: pointer;
}

.stocks__politics-checkbox {
  margin-right: 15px;
}

.stocks__politics-text {
  padding-top: 4px;

  line-height: 1.1;

  color: #313131;
}

.error .stocks__politics-text {
  color: #d92641;
}

.stocks__right {
  position: relative;

  flex-grow: 1;

  overflow: hidden;
}

.stocks__img {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  object-fit: cover;
  object-position: center;

  animation: increase-stock 30s infinite ease-in-out alternate;
}

@keyframes increase-stock {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.2);
  }
}

@media screen and (max-width: 1599.98px) {
  .stocks {
    min-height: 728px;
    margin-bottom: 150px;
  }

  .stocks__left {
    padding: 100px 90px 100px 0;
  }

  .stocks__content {
    width: 430px;
  }

  .stocks__title {
    margin-bottom: 12px;
  }

  .stocks__subtitle {
    padding-right: 0;
    margin-bottom: 50px;
  }

  .stocks__timer {
    margin-bottom: 50px;
  }

  .stocks__field {
    width: 276px;
  }

  .stocks__field:nth-child(2) {
    margin-bottom: 30px;
  }

  .stocks__button {
    width: 276px;
    margin-bottom: 20px;
  }

  .stocks__politics-checkbox {
    margin-right: 10px;
  }

  .stocks__politics-text {
    padding-top: 1px;
  }
}

@media screen and (max-width: 1199.98px) {
  .stocks {
    min-height: 645px;
    margin-bottom: 130px;
  }

  .stocks__left {
    padding: 80px 83px 80px 0;
  }

  .stocks__content {
    width: 384px;
  }

  .stocks__title {
    margin-bottom: 10px;
  }

  .stocks__subtitle {
    margin-bottom: 45px;
  }

  .stocks__timer {
    margin-bottom: 45px;
  }

  .stocks__field {
    width: 248px;
  }

  .stocks__field:nth-child(2) {
    margin-bottom: 25px;
  }

  .stocks__button {
    width: 248px;
  }

  .stocks__politics-text {
    padding-top: 3px;
  }
}

@media screen and (max-width: 991.98px) {
  .stocks {
    min-height: 591px;
    margin-bottom: 110px;
  }

  .stocks__left {
    width: calc(50% + 44px);
    padding: 80px 34px 80px 0;
  }

  .stocks__content {
    width: 306px;
  }

  .stocks__timer {
    margin-bottom: 30px;
  }

  .stocks__field {
    width: 286px;
  }

  .stocks__button {
    width: 286px;
  }
}

@media screen and (max-width: 767.98px) {
  .stocks {
    flex-direction: column;
    min-height: auto;
    margin-bottom: 90px;
  }

  .stocks__left {
    justify-content: center;
    width: 100%;
    padding: 70px 0;
  }

  .stocks__content {
    width: 290px;
  }

  .stocks__subtitle {
    margin-bottom: 30px;
  }

  .stocks__field {
    width: 290px;
  }

  .stocks__button {
    width: 290px;
  }

  .stocks__right {
    position: relative;
  }

  .stocks__right::after {
    content: "";
    display: block;
    padding-top: 81.25%;
  }

  .stocks__img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}
</style>
