<template>
  <label :class="classObject" :for="name"><slot /></label>
</template>

<script>
export default {
  props: ["parentClass", "name"],
  data() {
    return {
      classObject: {
        ["label"]: true,
        [this.parentClass]: this.parentClass,
      },
    };
  },
};
</script>

<style>
.label {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #929292;
}

.label_color_blue {
  color: #5d7aac;
}

.error .label {
  color: #d92641;
}

@media screen and (max-width: 1599.98px) {
  .label {
    font-size: 12px;
  }
}

@media screen and (max-width: 1199.98px) {
  .label {
    font-size: 10px;
  }
}
</style>